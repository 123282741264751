/* .oznamko-img-wrapper {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.oznamko-img-wrapper::before {
    content: '';
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px; 
    bottom: -10px; 
    background-color: #f0f0f0cb; 
    z-index: -1;
    width: 100%;
    height: auto;
} */

.oznamko-svg {
    width: 30%;
    height: auto; 
    display: block;
    position: relative;
    margin: 0 auto; 
}

@media (max-aspect-ratio: 16/9) {
    .oznamko-svg {
        width: 35%;
    }
}

@media (max-aspect-ratio: 4/3) {
    .oznamko-svg {
        width: 40%;
    }
}

@media (max-aspect-ratio: 1/1) {
    .oznamko-svg {
        width: 60%;
    }
}

@media (max-aspect-ratio: 3/4) {
    .oznamko-svg {
        width: 80%;
    }
}

@media (max-aspect-ratio: 9/16) {
    .oznamko-svg {
        width: 95%;
    }
}

.oznamko {
    height: 100%; 
    width: auto;
}

/* .oznamko-svg {
    width: auto;
    height: 150%;
    display: block;
    position: relative;
    margin: 0 auto; 
}

.oznamko {
    width: auto;
    height: 150%;
} */



#pair_photo {
    width: 70%;
    margin: 0 auto;
    position: relative;
    display: block; /* Ensure it's a block element */
}

.pair-photo-wrapper {
    position: relative;
    width: 200%; /* Adjusted to 100% to fit the container */
    height: auto; /* Maintain aspect ratio */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto; /* Center horizontally */
    padding: 10%;
    left: -60%;
}

.pair-photo-wrapper::before {
    content: '';
    position: absolute;
    top: -10px; /* Adjust as needed */
    left: -10px; /* Adjust as needed */
    right: -10px; /* Adjust as needed */
    bottom: -10px; /* Adjust as needed */
    background-color: #f0f0f0cb; /* Adjust as needed */
    z-index: -1;
    width: 100%;
    height: auto;
    margin: 0 auto;
}
