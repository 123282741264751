#pair_photo {
    width: 70%;
    margin: 0 auto;
    position: relative;
    display: block; /* Ensure it's a block element */
}

.pair-photo-wrapper {
    position: relative;
    width: 200%; /* Adjusted to 100% to fit the container */
    height: auto; /* Maintain aspect ratio */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto; /* Center horizontally */
    padding: 10%;
    left: -60%;
}

.pair-photo-wrapper::before {
    content: '';
    position: absolute;
    top: -10px; /* Adjust as needed */
    left: -10px; /* Adjust as needed */
    right: -10px; /* Adjust as needed */
    bottom: -10px; /* Adjust as needed */
    background-color: #f0f0f0cb; /* Adjust as needed */
    z-index: -1;
    width: 100%;
    height: auto;
    margin: 0 auto;
}
