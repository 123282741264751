/* Global Styles */
@import url(https://db.onlinewebfonts.com/c/f8deb3c1a029ec56b15bb462c1518f07?family=Snell+Roundhand+W01+Script);
@import url(https://db.onlinewebfonts.com/c/16efa2896f117dae2debeb23ab4715dd?family=Didot+eText+W01+Italic);
body {
	font-family:  sans-serif;
	background-color: #ffffff;
	color: gray;
	margin: 0;
	padding: 0;
	--labelsFont: normal normal normal 74px/1.4em Snell Roundhand W01 Script,cursive;
	--textsFont: normal normal normal 19px/1.4em didot-w01-italic,serif;
  }

  nav {
	position: fixed;
	top: 0;
	width: 100%;
	background-color: white;
	opacity: 0.5;
	padding: 1rem;
	z-index: 1000;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	font: var(--fnt,var(--font_1));
	--font_1: normal normal normal 16px/1.4em din-next-w01-light,sans-serif;
  }
  
  nav ul {
	list-style: none;
	display: flex;
	justify-content: center;
	margin: 0;
	padding: 0;

  }
  
  nav ul li {
	margin: 0;
	padding-left: 2%;
	padding-right: 2%;
  }
  
  nav ul li a {
	color: #4a4a4a;
	text-decoration: none;
	cursor: pointer;
	opacity: 1;
  }
  
  nav ul li a:hover {
	color: gray;
  }
  
  section {
	padding: 5rem 1rem;
	margin-top: 1rem;
	display: flex;
	flex-direction: column;
	align-items: center;
  }
  
  h1, h2 {
	color: gray;
	font-family: "Snell Roundhand W01 Script";

	font: var(--labelsFont);
  }
  h3 {
	color: gray;
	font: var(--textsFont);
	font-size: 1.5rem;
	font-style: italic;
}
  h4 {
	color: gray;
	font: var(--textsFont);
	font-size: 2.4rem;
	font-style: italic;
  }

  
  button {
	background-color: gray;
	color: white;
	border: none;
	border-radius: 4px;
	padding: 0.5rem 1rem;
	cursor: pointer;
	margin-top: -0.3rem;
  }
  
  button:hover {
	background-color: rgba(36, 36, 36, 0.815);
  }
  .rsvp {
	padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
	width: 100%;
  }
 .welcome-grid {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	gap: 1rem;
	width: 100%;
	text-align: center;
    align-items: center;
	justify-content: center;
	position: relative;
  }
  .welcome-grid-item {
	padding: 0.4rem;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	background-color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	height: 100%;
	

  }
  .welcome {
	padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .admin:h1, h2 {
	font: var(--textsFont);
	font-size: 3rem;
  }

#oznamko {
	width: 100%;
	margin: 0 auto;
	align-items: center;
	height: auto;
}

@media (max-width: 600px) {
	#oznamko {
		width: auto;
		margin: 0 auto;
		align-items: center;
		height: 100%;
	}
}

#couple-photo {
	width: 70%;
	margin: 0 auto;
	align-items: center;
	height: auto;
	}
	
