.gift {
    /* border: 1px solid gray; */
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    padding-bottom: 0.4rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: 100%;
    font: var(--textsFont);
    position: relative;
  }
  
  .gift img {
    width: 100%;
    height: auto;
    border-radius: 8px;    
  }
  .price {
    
  }
  .gift form {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .gift form div {
    margin-bottom: 0.5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .gift form label {
    display: block;
    margin-bottom: 0.25rem;
  }
  
  .gift form input {
    width: 80%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .gift form button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    background-color: gray;
    color: white;
    cursor: pointer;
  }
  
  
  .payment-info {
    margin-top: 1rem;
    text-align: left;
  }
  
  .payment-info img {
    margin-top: 1rem;
    max-width: 100%;
  }

  .progress-bar-container {
    width: 100%;
    background-color: #e0e0e0;
    border-radius: 8px;
    overflow: hidden;
    margin: 8px 0;
    
  }
  
  .progress-bar {
    height: 20px;
    background-color: #757575;
    border-radius: 8px;
  }
  .select-button {
    margin-top: 10px;
  }
