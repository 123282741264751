.gifts {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    font: var(--textsFont);
  }
  
  .gifts-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    justify-content: center;
    gap: 1rem;
    width: 70%;
    text-align: center;
    align-items: center;
    position: relative;
  }
  h1 {
    text-align: center;
  }

  .gifts_description {
    text-align: center;
    font-size: 1.5rem;
    font-style: italic;
    margin-bottom: 1rem;
    width: 70%;
  }
